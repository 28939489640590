import { types } from 'react-bricks/frontend';

// Heroes
import HeroTextAndButton from './Heroes/TextAndButton';
import HeroTextAndForm from './Heroes/TextAndForm';

// Features
import FeaturesStyleA from './Features/StyleA';
import FeaturesStyleAItem from './Features/StyleA/Item';

import FeaturesStyleB from './Features/StyleB';
import FeaturesStyleBItem from './Features/StyleB/Item';

import FeaturesStyleC from './Features/StyleC';
import FeaturesStyleCItem from './Features/StyleC/Item';

// Explainers
import ExplainersTwoByOne from './Explainers/TwoByOne';

// Teams
import TeamsStyleA from './Teams/StyleA';
import TeamsStyleAItem from './Teams/StyleA/Item';

import TeamsStyleB from './Teams/StyleB';
import TeamsStyleBItem from './Teams/StyleB/Item';

import TeamsStyleC from './Teams/StyleC';
import TeamsStyleCItem from './Teams/StyleC/Item';

// Forms
import FormSectionWithText from './FormSections/WithText';

// FAQs
import FaqsStyleA from './Faqs/StyleA';
import FaqsStyleAItem from './Faqs/StyleA/Item';

// Testimonials
import TestimonialsStaticThree from './Testimonials/StaticThree';
import TestimonialsStaticThreeItem from './Testimonials/StaticThree/Item';

// Pricing
import PriceCalculator from './Pricing/Calculator';

// CTAs
import CTAsStyleA from './CTAs/StyleA';

const bricks: types.Brick<any>[] = [
  HeroTextAndButton,
  HeroTextAndForm,
  FeaturesStyleA,
  FeaturesStyleAItem,
  FeaturesStyleB,
  FeaturesStyleBItem,
  FeaturesStyleC,
  FeaturesStyleCItem,
  ExplainersTwoByOne,
  TeamsStyleA,
  TeamsStyleAItem,
  TeamsStyleB,
  TeamsStyleBItem,
  TeamsStyleC,
  TeamsStyleCItem,
  FormSectionWithText,
  FaqsStyleA,
  FaqsStyleAItem,
  TestimonialsStaticThree,
  TestimonialsStaticThreeItem,
  PriceCalculator,
  CTAsStyleA,
];

export default bricks;
