import React, { useState } from 'react';
import { types, Text, Repeater } from 'react-bricks/frontend';
import { Accordion } from 'react-accessible-accordion';
import cx from 'classnames';
import Container from '../../../../components/Container';

interface FaqsStyleAProps {
  sectionTitle: string;
  sectionDescription: string;
  faqsStyleAItems: [];
  enableDescription: boolean;
  bgColor: types.IColor;
  titleColor: types.IColor;
  descriptionColor: types.IColor;
}

const FaqsStyleA: types.Brick<FaqsStyleAProps> = ({
  faqsStyleAItems,
  enableDescription,
  bgColor,
  titleColor,
  descriptionColor,
}) => {
  const [itemsExpanded, setItemsExpanded] = useState([]);

  return (
    <Container className={cx('py-8', bgColor?.className)}>
      <div className="flex flex-col items-center">
        <div className="text-center mb-10">
          <div className="mx-auto">
            <Text
              propName="sectionTitle"
              placeholder="Title"
              renderBlock={({ children }) => (
                <h2
                  className={`text-3xl font-bold font-alata ${titleColor?.className}`}
                >
                  {children}
                </h2>
              )}
            />
            {enableDescription ? (
              <Text
                propName="sectionDescription"
                placeholder="Description"
                renderBlock={({ children }) => (
                  <p
                    className={`text-base mt-2 ${descriptionColor?.className}`}
                  >
                    {children}
                  </p>
                )}
              />
            ) : null}
          </div>
        </div>
        <Accordion
          className="w-full"
          allowMultipleExpanded={false}
          allowZeroExpanded
          onChange={uuids => setItemsExpanded(uuids)}
        >
          <Repeater
            propName="faqsStyleAItems"
            itemProps={{ totalElements: faqsStyleAItems.length, itemsExpanded }}
          />
        </Accordion>
      </div>
    </Container>
  );
};

FaqsStyleA.schema = {
  name: 'faqsStyleA',
  label: 'FAQs: Style A',
  getDefaultProps: () => ({
    sectionTitle: 'FAQ',
    sectionDescription:
      'Dignissimos dolores quam, nobis quaerat laboriosam. Lorem ipsum dolor sit amet',
    faqsStyleAItems: [],
    enableDescription: true,
    bgColor: {
      color: 'transparent',
      className: 'bg-transparent',
    },
    titleColor: {
      color: '#1A202C',
      className: 'text-gray-800',
    },
    descriptionColor: {
      color: '#1A202C',
      className: 'text-gray-800',
    },
  }),
  sideEditProps: [
    {
      name: 'enableDescription',
      label: 'Enable Description?',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'bgColor',
      label: 'Background Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'Transparent',
            value: {
              color: 'transparent',
              className: 'bg-transparent',
            },
          },
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'bg-ktblue-500',
            },
          },
          {
            label: 'KT Blue (Light)',
            value: {
              color: '#65AAC3',
              className: 'bg-ktblue-300',
            },
          },
          {
            label: 'KT Blue (Lighter)',
            value: {
              color: '#F1F7F9',
              className: 'bg-ktblue-50',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'bg-ktorange-500',
            },
          },
          {
            label: 'KT Orange (Light)',
            value: {
              color: '#F8BC7E',
              className: 'bg-ktorange-300',
            },
          },
          {
            label: 'KT Orange (Lighter)',
            value: {
              color: '#FCE4CC',
              className: 'bg-ktorange-100',
            },
          },
        ],
      },
    },
    {
      name: 'titleColor',
      label: 'Title Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'Black',
            value: {
              color: '#1A202C',
              className: 'text-gray-800',
            },
          },
          {
            label: 'White',
            value: {
              color: '#FFFFFF',
              className: 'text-white',
            },
          },
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'text-ktblue-500',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'text-ktorange-500',
            },
          },
        ],
      },
    },
    {
      name: 'descriptionColor',
      label: 'Description Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'Black',
            value: {
              color: '#1A202C',
              className: 'text-gray-800',
            },
          },
          {
            label: 'White',
            value: {
              color: '#FFFFFF',
              className: 'text-white',
            },
          },
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'text-ktblue-500',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'text-ktorange-500',
            },
          },
        ],
      },
    },
  ],
  repeaterItems: [
    {
      name: 'faqsStyleAItems',
      itemType: 'faqsStyleAItem',
      itemLabel: 'Item',
      min: 1,
    },
  ],
};

export default FaqsStyleA;
