import React from 'react';
import cx from 'classnames';

interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  type?: 'button' | 'link';
  htmlType?: 'button' | 'submit' | 'reset';
  href?: string;
}

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  type = 'button',
  htmlType = 'button',
  href = '/#',
  ...rest
}) => {
  const classes = cx(
    'inline-block px-10 py-3 font-medium text-center font-bold rounded-full focus:ring-2 focus:ring-ktblue-400 md:whitespace-nowrap',
    { 'text-white': !className?.split(' ').includes('bg-white') },
    className,
  );

  if (type === 'button') {
    return (
      <button type={htmlType} className={classes} {...rest}>
        {children}
      </button>
    );
  }
  return (
    <a className={classes} href={href} {...rest}>
      {children}
    </a>
  );
};

export default Button;
