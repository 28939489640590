import React, { useState, useEffect } from 'react';
import { types, Text, RichText } from 'react-bricks/frontend';
import Container from '../../../components/Container';
import HubspotForm from '../../../components/HubspotForm';

interface HeroTextAndFormProps {
  heading: string;
  subHeading: string;
  headingColor: types.IColor;
  descriptionColor: types.IColor;
  formBgColor: types.IColor;
  formTextColor: types.IColor;
  enableOverlay: boolean;
  heroImg: types.IImageSource;
  overlayDegree: number;
  overlayType: string;
  formId: string;
}

const HeroTextAndForm: types.Brick<HeroTextAndFormProps> = ({
  headingColor,
  descriptionColor,
  formBgColor,
  formTextColor,
  heroImg,
  enableOverlay,
  overlayType,
  overlayDegree,
  formId,
}) => {
  const [hsFormId, setHsFormId] = useState(undefined);

  useEffect(() => {
    if (formId) {
      setHsFormId(formId);
    }
  }, [formId]);

  return (
    <div
      className="w-full py-40 bg-no-repeat bg-cover bg-center min-h-[50vh]"
      style={{
        backgroundImage: `${
          enableOverlay
            ? `linear-gradient(${overlayDegree}deg, rgba(0, 0, 0, ${
                overlayType === 'gradient' ? '0.8' : '0.5'
              }), rgba(0, 0, 0, ${
                overlayType === 'gradient' ? '0.0' : '0.5'
              })),`
            : ''
        } url(${heroImg?.src})`,
      }}
    >
      <Container className="flex justify-between gap-14 items-center">
        <div className="max-w-md">
          <RichText
            propName="heading"
            renderBlock={({ children }) => (
              <h1 className={`text-4xl mb-6 ${headingColor?.className}`}>
                {children}
              </h1>
            )}
            placeholder="Heading"
            allowedFeatures={[types.RichTextFeatures.Bold]}
          />
          <Text
            propName="subHeading"
            renderBlock={({ children }) => (
              <p className={`text-base mb-4 ${descriptionColor?.className}`}>
                {children}
              </p>
            )}
            placeholder="Subheading"
          />
        </div>
        <div
          className={`${formBgColor?.className} bg-opacity-80 rounded-sm px-4 py-12 w-1/2 2xl:w-1/3 my-10`}
        >
          <RichText
            propName="formDescription"
            placeholder="Type a text..."
            renderBlock={({ children }) => (
              <p className={`text-sm ${formTextColor?.className}`}>
                {children}
              </p>
            )}
            allowedFeatures={[
              types.RichTextFeatures.Bold,
              types.RichTextFeatures.Italic,
              types.RichTextFeatures.Highlight,
            ]}
          />
          {formId !== '' ? (
            <HubspotForm key={hsFormId} formId={hsFormId} />
          ) : null}
        </div>
      </Container>
    </div>
  );
};

HeroTextAndForm.schema = {
  name: 'heroTextAndForm',
  label: 'Heroes: Text & Form',
  getDefaultProps: () => ({
    heading: 'Lorem ipsum, dolor sit amet',
    subHeading:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Recusandae odio molestias, voluptatum officia et quidem consequuntur, exercitationem doloremque, fugit maxime cumque. Earum necessitatibus id, facilis aliquam iure repellat dolor dignissimos.',
    enableOverlay: true,
    headingColor: {
      color: '#FFFFFF',
      className: 'text-white',
    },
    descriptionColor: {
      color: '#FFFFFF',
      className: 'text-white',
    },
    formBgColor: {
      color: '#1A202C',
      className: 'bg-gray-800',
    },
    formTextColor: {
      color: '#FFFFFF',
      className: 'text-white',
    },
    overlayDegree: 90,
    overlayType: 'gradient',
    formId: undefined,
  }),
  sideEditProps: [
    {
      name: 'formId',
      label: 'Hubspot Form ID',
      type: types.SideEditPropType.Text,
    },
    {
      name: 'heroImg',
      label: 'Background Image',
      type: types.SideEditPropType.Image,
      imageOptions: {
        maxWidth: 1920,
      },
    },
    {
      name: 'enableOverlay',
      label: 'Enable overlay for image?',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'overlayType',
      label: 'Overlay Type',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Radio,
        options: [
          { label: 'Solid', value: 'solid' },
          { label: 'Gradient', value: 'gradient' },
        ],
      },
      show: props => !!props.enableOverlay,
    },
    {
      name: 'overlayDegree',
      label: 'Overlay Gradient Direction',
      type: types.SideEditPropType.Range,
      rangeOptions: {
        min: 0,
        max: 270,
        step: 90,
      },
      show: props => !!props.enableOverlay && props.overlayType === 'gradient',
    },
    {
      name: 'headingColor',
      label: 'Heading Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'Black',
            value: {
              color: '#1A202C',
              className: 'text-gray-800',
            },
          },
          {
            label: 'White',
            value: {
              color: '#FFFFFF',
              className: 'text-white',
            },
          },
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'text-ktblue-500',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'text-ktorange-500',
            },
          },
        ],
      },
    },
    {
      name: 'descriptionColor',
      label: 'Description Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'Black',
            value: {
              color: '#1A202C',
              className: 'text-gray-800',
            },
          },
          {
            label: 'White',
            value: {
              color: '#FFFFFF',
              className: 'text-white',
            },
          },
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'text-ktblue-500',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'text-ktorange-500',
            },
          },
        ],
      },
    },
    {
      name: 'formBgColor',
      label: 'Form Background Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'Black',
            value: {
              color: '#1A202C',
              className: 'bg-gray-800',
            },
          },
          {
            label: 'White',
            value: {
              color: '#FFFFFF',
              className: 'bg-white',
            },
          },
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'bg-ktblue-500',
            },
          },
          {
            label: 'KT Blue (Light)',
            value: {
              color: '#65AAC3',
              className: 'bg-ktblue-300',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'bg-ktorange-500',
            },
          },
          {
            label: 'KT Orange (Light)',
            value: {
              color: '#F8BC7E',
              className: 'bg-ktorange-300',
            },
          },
        ],
      },
    },
    {
      name: 'formTextColor',
      label: 'Form text color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'Black',
            value: {
              color: '#1A202C',
              className: 'text-gray-800',
            },
          },
          {
            label: 'White',
            value: {
              color: '#FFFFFF',
              className: 'text-white',
            },
          },
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'text-ktblue-500',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'text-ktorange-500',
            },
          },
        ],
      },
    },
  ],
};

export default HeroTextAndForm;
