import React from 'react';
import { types, Image, Text } from 'react-bricks/frontend';
import cx from 'classnames';

interface FeaturesStyleCItemProps {
  enableIconBg: boolean;
  iconBgColor: types.IColor;
  iconOrImg: string;
  iconName: string;
  iconColor: string;
  enableCards: boolean;
  alignment: string;
}

const FeaturesStyleCItem: types.Brick<FeaturesStyleCItemProps> = ({
  enableIconBg,
  iconBgColor,
  iconOrImg,
  iconName,
  iconColor,
  enableCards,
  alignment,
  ...rest
}) => {
  return (
    <div className="basis-1/5" {...rest}>
      <div
        className={cx('py-10 px-8 md:min-h-[275px] lg:min-h-[260px]', {
          'bg-white rounded-md drop-shadow-xl border-[1px]': enableCards,
        })}
      >
        <div
          className={cx('flex flex-col gap-4', {
            'items-start': alignment === 'left',
            'items-center': alignment === 'center',
            'items-end': alignment === 'right',
          })}
        >
          <div className="mr-3">
            <div
              className={cx('flex', {
                [`w-12 h-12 justify-center items-center rounded-full ${iconBgColor?.className}`]:
                  enableIconBg,
              })}
            >
              {iconOrImg === 'icon' ? (
                <i
                  className={iconName}
                  style={{ transform: 'scale(1)', color: iconColor }}
                />
              ) : (
                <Image
                  propName="iconImg"
                  alt="altText"
                  renderWrapper={({ children }) => (
                    <div className="w-10 h-10">{children}</div>
                  )}
                  imageClassName="w-10 h-10"
                />
              )}
            </div>
          </div>
          <div
            className={cx({
              'text-left': alignment === 'left',
              'text-center': alignment === 'center',
              'text-right': alignment === 'right',
            })}
          >
            <Text
              propName="title"
              renderBlock={({ children }) => (
                <h3 className="mb-2 text-2xl font-bold font-alata">
                  {children}
                </h3>
              )}
              placeholder="Title of feature"
            />
            <Text
              propName="description"
              renderBlock={({ children }) => <p>{children}</p>}
              placeholder="Description of feature goes here."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

FeaturesStyleCItem.schema = {
  name: 'featuresStyleCItem',
  label: 'Features: Style C Item',
  hideFromAddMenu: true,
  getDefaultProps: () => ({
    title: 'Great Features',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis quibusdam eius libero atque eligendi esse eveniet in suscipit qui. Minima placeat id nam cum unde minus modi dignissimos quae aperiam.',
    enableIconBg: true,
    iconBgColor: {
      color: '#3C819A',
      className: 'bg-ktblue-500',
    },
    iconOrImg: 'icon',
    iconName: 'gg-smartphone-chip',
    iconColor: '#FFFFFF',
  }),
  sideEditProps: [
    {
      name: 'enableIconBg',
      label: 'Enable icon background',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'iconBgColor',
      label: 'Icon Background Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'bg-ktblue-500',
            },
          },
          {
            label: 'KT Blue (Light)',
            value: {
              color: '#65AAC3',
              className: 'bg-ktblue-300',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'bg-ktorange-500',
            },
          },
          {
            label: 'KT Orange (Light)',
            value: {
              color: '#F8BC7E',
              className: 'bg-ktorange-300',
            },
          },
        ],
      },
      show: props => !!props.enableIconBg,
    },
    {
      name: 'iconOrImg',
      label: 'Use CSS Icon or Image?',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Radio,
        options: [
          { value: 'icon', label: 'CSS icon' },
          { value: 'img', label: 'Image' },
        ],
      },
    },
    {
      name: 'iconName',
      label: 'Icon',
      type: types.SideEditPropType.Text,
      show: props => props.iconOrImg === 'icon',
    },
    {
      name: 'iconColor',
      label: 'Icon Color Hex Code',
      type: types.SideEditPropType.Text,
      show: props => props.iconOrImg === 'icon',
    },
  ],
};

export default FeaturesStyleCItem;
