import React from 'react';
import { types, Text, RichText } from 'react-bricks/frontend';
import cx from 'classnames';
import Button from '../../../components/Button';
import Container from '../../../components/Container';

interface HeroTextAndButtonProps {
  heading: string;
  subHeading: string;
  heroHeight: number;
  headingColor: types.IColor;
  descriptionColor: types.IColor;
  enableOverlay: boolean;
  heroImg: types.IImageSource;
  enableBtn: boolean;
  btnText: string;
  contentPlacement: string;
  overlayDegree: number;
  overlayType: string;
}

const HeroTextAndButton: types.Brick<HeroTextAndButtonProps> = ({
  heroHeight,
  headingColor,
  descriptionColor,
  heroImg,
  enableOverlay,
  enableBtn,
  btnText,
  contentPlacement,
  overlayType,
  overlayDegree,
}) => {
  return (
    <div
      className={cx(
        'flex w-full items-center bg-no-repeat bg-cover bg-center',
        {
          'justify-start': contentPlacement === 'left',
          'justify-center': contentPlacement === 'center',
          'flex-row-reverse': contentPlacement === 'right',
        },
      )}
      style={{
        backgroundImage: `${
          enableOverlay
            ? `linear-gradient(${overlayDegree}deg, rgba(0, 0, 0, ${
                overlayType === 'gradient' ? '0.8' : '0.5'
              }), rgba(0, 0, 0, ${
                overlayType === 'gradient' ? '0.0' : '0.5'
              })),`
            : ''
        } url(${heroImg?.src})`,
        height: `${heroHeight}vh`,
      }}
    >
      <Container>
        <div
          className={cx('max-w-md', {
            'text-left': contentPlacement !== 'center',
            'text-center': contentPlacement === 'center',
          })}
        >
          <RichText
            propName="heading"
            renderBlock={({ children }) => (
              <h1 className={`text-4xl mb-6 ${headingColor?.className}`}>
                {children}
              </h1>
            )}
            placeholder="Heading"
            allowedFeatures={[types.RichTextFeatures.Bold]}
          />
          <Text
            propName="subHeading"
            renderBlock={({ children }) => (
              <p className={`text-base mb-4 ${descriptionColor?.className}`}>
                {children}
              </p>
            )}
            placeholder="Subheading"
          />
          {enableBtn ? <Button>{btnText}</Button> : null}
        </div>
      </Container>
    </div>
  );
};

HeroTextAndButton.schema = {
  name: 'heroTextAndButton',
  label: 'Heroes: Text & Button',
  getDefaultProps: () => ({
    heading: 'Lorem ipsum, dolor sit amet',
    subHeading:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Recusandae odio molestias, voluptatum officia et quidem consequuntur, exercitationem doloremque, fugit maxime cumque. Earum necessitatibus id, facilis aliquam iure repellat dolor dignissimos.',
    heroHeight: 65,
    enableOverlay: true,
    headingColor: {
      color: '#FFFFFF',
      className: 'text-white',
    },
    descriptionColor: {
      color: '#FFFFFF',
      className: 'text-white',
    },
    enableBtn: true,
    btnText: 'Call to Action',
    contentPlacement: 'left',
    overlayDegree: 90,
    overlayType: 'gradient',
  }),
  sideEditProps: [
    {
      name: 'heroHeight',
      label: 'Height',
      type: types.SideEditPropType.Range,
      rangeOptions: {
        min: 35,
        max: 100,
        step: 5,
      },
    },
    {
      name: 'heroImg',
      label: 'Background Image',
      type: types.SideEditPropType.Image,
      imageOptions: {
        maxWidth: 1920,
      },
    },
    {
      name: 'enableOverlay',
      label: 'Enable overlay for image?',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'overlayType',
      label: 'Overlay Type',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Radio,
        options: [
          { label: 'Solid', value: 'solid' },
          { label: 'Gradient', value: 'gradient' },
        ],
      },
      show: props => !!props.enableOverlay,
    },
    {
      name: 'overlayDegree',
      label: 'Overlay Gradient Direction',
      type: types.SideEditPropType.Range,
      rangeOptions: {
        min: 0,
        max: 270,
        step: 90,
      },
      show: props => !!props.enableOverlay && props.overlayType === 'gradient',
    },
    {
      name: 'contentPlacement',
      label: 'Content Placement',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Select,
        options: [
          { label: 'Left', value: 'left' },
          { label: 'Center', value: 'center' },
          { label: 'Right', value: 'right' },
        ],
      },
    },
    {
      name: 'headingColor',
      label: 'Heading Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'Black',
            value: {
              color: '#1A202C',
              className: 'text-gray-800',
            },
          },
          {
            label: 'White',
            value: {
              color: '#FFFFFF',
              className: 'text-white',
            },
          },
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'text-ktblue-500',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'text-ktorange-500',
            },
          },
        ],
      },
    },
    {
      name: 'descriptionColor',
      label: 'Description Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'Black',
            value: {
              color: '#1A202C',
              className: 'text-gray-800',
            },
          },
          {
            label: 'White',
            value: {
              color: '#FFFFFF',
              className: 'text-white',
            },
          },
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'text-ktblue-500',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'text-ktorange-500',
            },
          },
        ],
      },
    },
    {
      name: 'enableBtn',
      label: 'Enable button?',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'btnText',
      label: 'Button Text',
      type: types.SideEditPropType.Text,
      show: props => !!props.enableBtn,
    },
  ],
};

export default HeroTextAndButton;
