import React from 'react';
import cx from 'classnames';
import * as SwitchPrimitive from '@radix-ui/react-switch';
import type { SwitchProps } from '@radix-ui/react-switch';

const Switch = ({ ...rest }: SwitchProps) => {
  return (
    <SwitchPrimitive.Root
      className={cx(
        'group',
        'radix-state-checked:bg-ktblue-500',
        'radix-state-unchecked:bg-gray-300',
        'relative inline-flex h-[18px] w-[36px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
        'focus:outline-none focus-visible:ring focus-visible:ring-ktblue-500 focus-visible:ring-opacity-75',
      )}
      {...rest}
    >
      <SwitchPrimitive.Thumb
        className={cx(
          'group-radix-state-checked:translate-x-5',
          'group-radix-state-unchecked:translate-x-0',
          'pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out',
        )}
      />
    </SwitchPrimitive.Root>
  );
};

export default Switch;
