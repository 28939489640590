import React from 'react';
import { types, RichText, Image } from 'react-bricks/frontend';
import cx from 'classnames';
import { useMediaQuery } from '@mantine/hooks';
import Button from '../../../components/Button';
import Container from '../../../components/Container';

interface CTAsStyleAProps {
  enableDesc: boolean;
  bgColor: types.IColor;
  btnColor: types.IColor;
  btnText: string;
  openInNewTab: boolean;
  btnLink: string;
  ctaImg: types.IImageSource;
  imgSizeDesktop: string;
  imgSizeMobile: string;
  imgBottomPos: string;
  imgAlignment: 'left' | 'right';
  imgMirror: boolean;
}

const CTAsStyleA: types.Brick<CTAsStyleAProps> = ({
  enableDesc,
  bgColor,
  btnColor,
  btnText,
  openInNewTab,
  btnLink,
  imgSizeDesktop,
  imgSizeMobile,
  imgBottomPos,
  imgAlignment,
  imgMirror,
}) => {
  const isMobileOrTablet = useMediaQuery('(max-width: 1023px)', true);

  return (
    <Container className="py-10">
      <div
        className={cx(
          'relative w-full text-center min-h-[25vh] rounded-lg xs:pt-14 lg:py-14 xs:px-4',
          bgColor?.className,
        )}
      >
        <div className="mb-8 md:mb-0">
          <div className="max-w-md mx-auto">
            <RichText
              propName="title"
              renderBlock={({ children }) => (
                <h3 className="text-3xl mb-6 text-white font-bold font-alata">
                  {children}
                </h3>
              )}
              placeholder="Title"
              allowedFeatures={[types.RichTextFeatures.Bold]}
            />
            {enableDesc ? (
              <RichText
                propName="description"
                renderBlock={({ children }) => (
                  <h3 className="mb-6 text-white">{children}</h3>
                )}
                placeholder="Description"
                allowedFeatures={[types.RichTextFeatures.Bold]}
              />
            ) : null}
          </div>
          <Button
            className={btnColor?.className}
            type="link"
            href={btnLink}
            {...(openInNewTab && {
              target: '_blank',
              rel: 'noopener noreferrer',
            })}
          >
            {btnText}
          </Button>
        </div>
        <div
          className={cx(
            'mt-6 mx-auto lg:mt-0 lg:mx-0 lg:absolute',
            { 'lg:left-14': imgAlignment === 'left' },
            { 'lg:right-14': imgAlignment === 'right' },
          )}
          style={{
            bottom: parseInt(imgBottomPos, 10),
            maxWidth: isMobileOrTablet
              ? parseInt(imgSizeMobile, 10)
              : parseInt(imgSizeDesktop, 10),
          }}
        >
          <Image
            propName="ctaImg"
            alt="CTA Image"
            renderWrapper={({ children }) => (
              <div className="min-h-[80px] min-w-[80px]">{children}</div>
            )}
            imageStyle={{
              width: isMobileOrTablet
                ? parseInt(imgSizeMobile, 10)
                : parseInt(imgSizeDesktop, 10),
              ...(imgMirror && { transform: 'scale(-1, 1)' }),
            }}
          />
          {/* <img src={ctaImg ? ctaImg.src : dude} /> */}
        </div>
      </div>
    </Container>
  );
};

CTAsStyleA.schema = {
  name: 'ctasStyleA',
  label: 'CTAs: Style A',
  getDefaultProps: () => ({
    enableDesc: true,
    title: 'Lorem ipsum',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Recusandae odio molestias, voluptatum officia et quidem consequuntur.',
    btnText: 'Call to Action',
    btnLink: '/#',
    openInNewTab: false,
    bgColor: {
      color: '#f5bd33',
      className: 'bg-payroll-500',
    },
    btnColor: {
      color: '#42b4ce',
      className: 'bg-leave-500 hover:bg-leave-600',
    },
    imgSizeDesktop: 100,
    imgSizeMobile: 100,
    imgBottomPos: 0,
    imgAlignment: 'left',
    imgMirror: false,
  }),
  sideEditProps: [
    {
      name: 'enableDesc',
      label: 'Enable Description?',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'bgColor',
      label: 'Background Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'Payroll Gold',
            value: {
              color: '#f5bd33',
              className: 'bg-payroll-500',
            },
          },
          {
            label: 'Leave Blue',
            value: {
              color: '#42b4ce',
              className: 'bg-leave-500',
            },
          },
          {
            label: 'Claims Purple',
            value: {
              color: '#682edc',
              className: 'bg-claim-500',
            },
          },
          {
            label: 'Attendance Pink',
            value: {
              color: '#dd4db8',
              className: 'bg-attendance-500',
            },
          },
          {
            label: 'HR Premium Orange',
            value: {
              color: '#d17931',
              className: 'bg-hrpremium-500',
            },
          },
          {
            label: 'HR Sifu Blue',
            value: {
              color: '#3c57d2',
              className: 'bg-hrsifu-500',
            },
          },
        ],
      },
    },
    {
      groupName: 'Button Props',
      props: [
        {
          name: 'btnColor',
          label: 'Button Color',
          type: types.SideEditPropType.Select,
          selectOptions: {
            display: types.OptionsDisplay.Color,
            options: [
              {
                label: 'Payroll Gold',
                value: {
                  color: '#f5bd33',
                  className: 'bg-payroll-500 hover:bg-payroll-600',
                },
              },
              {
                label: 'Leave Blue',
                value: {
                  color: '#42b4ce',
                  className: 'bg-leave-500 hover:bg-leave-600',
                },
              },
              {
                label: 'Claims Purple',
                value: {
                  color: '#682edc',
                  className: 'bg-claim-500 hover:bg-claim-600',
                },
              },
              {
                label: 'Attendance Pink',
                value: {
                  color: '#dd4db8',
                  className: 'bg-attendance-500 hover:bg-attendance-600',
                },
              },
              {
                label: 'HR Premium Orange',
                value: {
                  color: '#d17931',
                  className: 'bg-hrpremium-500 hover:bg-hrpremium-600',
                },
              },
              {
                label: 'HR Sifu Blue',
                value: {
                  color: '#3c57d2',
                  className: 'bg-hrsifu-500 hover:bg-hrsifu-600',
                },
              },
            ],
          },
        },
        {
          name: 'btnText',
          label: 'Button Text',
          type: types.SideEditPropType.Text,
        },
        {
          name: 'btnLink',
          label: 'Button Link',
          type: types.SideEditPropType.Text,
        },
        {
          name: 'openInNewTab',
          label: 'Open In New Tab?',
          type: types.SideEditPropType.Boolean,
        },
      ],
    },
    {
      groupName: 'Image Props',
      props: [
        {
          name: 'imgSizeDesktop',
          label: 'CTA Image Size (Desktop)',
          type: types.SideEditPropType.Number,
        },
        {
          name: 'imgSizeMobile',
          label: 'CTA Image Size (Mobile/Tablet)',
          type: types.SideEditPropType.Number,
        },
        {
          name: 'imgBottomPos',
          label: 'Image Bottom Position',
          type: types.SideEditPropType.Range,
          rangeOptions: {
            min: -20,
            max: 20,
            step: 1,
          },
        },
        {
          name: 'imgAlignment',
          label: 'Image Alignment',
          type: types.SideEditPropType.Select,
          selectOptions: {
            display: types.OptionsDisplay.Radio,
            options: [
              { label: 'Left', value: 'left' },
              { label: 'Right', value: 'right' },
            ],
          },
        },
        {
          name: 'imgMirror',
          label: 'Mirror Image?',
          type: types.SideEditPropType.Boolean,
        },
      ],
    },
  ],
};

export default CTAsStyleA;
