import React from 'react';
import { types, Repeater, Text } from 'react-bricks/frontend';

interface TestimonialsStaticThreeProps {
  bgColor: types.IColor;
  enableDescription: boolean;
  titleColor: types.IColor;
  descriptionColor: types.IColor;
}

const TestimonialsStaticThree: types.Brick<TestimonialsStaticThreeProps> = ({
  bgColor,
  enableDescription,
  titleColor,
  descriptionColor,
}) => {
  return (
    <div>
      <div className={`py-8 px-36 ${bgColor?.className}`}>
        <div className="flex flex-col items-center">
          <div className="text-center mb-10">
            <div className="mx-auto">
              <Text
                propName="sectionTitle"
                placeholder="Title"
                renderBlock={({ children }) => (
                  <h2 className={`text-4xl font-bold ${titleColor?.className}`}>
                    {children}
                  </h2>
                )}
              />
              {enableDescription ? (
                <Text
                  propName="sectionDescription"
                  placeholder="Description"
                  renderBlock={({ children }) => (
                    <p
                      className={`text-base mt-2 ${descriptionColor?.className}`}
                    >
                      {children}
                    </p>
                  )}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex flex-row flex-wrap justify-evenly items-center w-full mx-auto py-8 px-20 gap-4 min-h-[35vh]">
          <Repeater propName="testimonialsStaticThreeItems" />
        </div>
      </div>
    </div>
  );
};

TestimonialsStaticThree.schema = {
  name: 'testimonialsStaticThree',
  label: 'Testimonials: Static Three',
  getDefaultProps: () => ({
    sectionTitle: 'What our customers are saying about us',
    sectionDescription:
      'Dignissimos dolores quam, nobis quaerat laboriosam. Lorem ipsum dolor sit amet',
    enableDescription: true,
    bgColor: {
      color: 'transparent',
      className: 'bg-transparent',
    },
    titleColor: {
      color: '#1A202C',
      className: 'text-gray-800',
    },
    descriptionColor: {
      color: '#1A202C',
      className: 'text-gray-800',
    },
    testimonialsStaticThreeItems: [],
  }),
  sideEditProps: [
    {
      name: 'enableDescription',
      label: 'Enable Description?',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'bgColor',
      label: 'Background Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'Transparent',
            value: {
              color: 'transparent',
              className: 'bg-transparent',
            },
          },
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'bg-ktblue-500',
            },
          },
          {
            label: 'KT Blue (Light)',
            value: {
              color: '#65AAC3',
              className: 'bg-ktblue-300',
            },
          },
          {
            label: 'KT Blue (Lighter)',
            value: {
              color: '#F1F7F9',
              className: 'bg-ktblue-50',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'bg-ktorange-500',
            },
          },
          {
            label: 'KT Orange (Light)',
            value: {
              color: '#F8BC7E',
              className: 'bg-ktorange-300',
            },
          },
          {
            label: 'KT Orange (Lighter)',
            value: {
              color: '#FCE4CC',
              className: 'bg-ktorange-100',
            },
          },
        ],
      },
    },
    {
      name: 'titleColor',
      label: 'Title Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'Black',
            value: {
              color: '#1A202C',
              className: 'text-gray-800',
            },
          },
          {
            label: 'White',
            value: {
              color: '#FFFFFF',
              className: 'text-white',
            },
          },
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'text-ktblue-500',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'text-ktorange-500',
            },
          },
        ],
      },
    },
    {
      name: 'descriptionColor',
      label: 'Description Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'Black',
            value: {
              color: '#1A202C',
              className: 'text-gray-800',
            },
          },
          {
            label: 'White',
            value: {
              color: '#FFFFFF',
              className: 'text-white',
            },
          },
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'text-ktblue-500',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'text-ktorange-500',
            },
          },
        ],
      },
    },
  ],
  repeaterItems: [
    {
      name: 'testimonialsStaticThreeItems',
      itemType: 'testimonialsStaticThreeItem',
      itemLabel: 'Item',
      min: 3,
      max: 3,
    },
  ],
};

export default TestimonialsStaticThree;
