import React, { useState, useEffect, useCallback } from 'react';
import { types } from 'react-bricks/frontend';
import { useDebouncedValue } from '@mantine/hooks';

import Container from '../../../components/Container';
import Slider from '../../../components/Slider';
import Switch from '../../../components/Switch';
import Button from '../../../components/Button';
import CalculatorModule from '../../../components/CalculatorModule';

import payrollIcon from './assets/payroll.svg';
import leaveIcon from './assets/leave.svg';
import claimIcon from './assets/claim.svg';
import attendanceIcon from './assets/attendance.svg';
import hrPremiumIcon from './assets/hr_premium.svg';

import priceTable from './prices.json';

const domain = 'https://www.kakitangan.com';

const Spinner = () => {
  return (
    <div className="w-full my-6">
      <i
        className="mx-auto text-ktblue-500 gg-spinner"
        style={{ transform: 'scale(1.5)' }}
      />
    </div>
  );
};

const PriceCalculator: types.Brick = () => {
  const [sliderVal, setSliderVal] = useState([3]);
  const [inputVal, setInputVal] = useState('3');
  const [selectedMods, setSelectedMods] = useState(['payroll']);
  const [term, setTerm] = useState<'monthly' | 'yearly'>('monthly');
  const [sumOfModulePrices, setSumOfModulePrices] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [debouncedInputVal] = useDebouncedValue(inputVal, 300);

  const onSliderValChange = val => {
    setIsLoading(true);
    setSliderVal(val);
    setInputVal(val[0].toString());
  };

  const tierSearch = (tiers: number[], target: number) => {
    if (target === 3) {
      return tiers[0];
    }

    if (target === 4 || target === 5) {
      return tiers[1];
    }

    for (let i = 2; i < tiers.length; i++) {
      if (target === tiers[i] || (target < tiers[i] && target > tiers[i - 1])) {
        return tiers[i];
      }
    }
  };

  const calculatePrice = (
    modules: string[],
    term: 'monthly' | 'yearly',
    numOfEmployees: number,
  ) => {
    let price = 0;

    let module: string | null = null;

    if (modules.length === 4 && !modules.includes('attendance')) {
      module = 'allWithoutAttendance';
    } else if (modules.length === 5) {
      module = 'allWithAttendance';
    }

    if (numOfEmployees > 350 && term === 'monthly') {
      if (!module) {
        modules.forEach(m => {
          price += priceTable[m]['350'][term];
        });
      } else {
        price = priceTable[module]['350'][term];
      }
    } else {
      if (!module) {
        modules.forEach(m => {
          let tier = tierSearch(
            Object.keys(priceTable[m]).map(k => parseInt(k, 10)),
            3,
          );
          if (numOfEmployees > 3) {
            tier = tierSearch(
              Object.keys(priceTable[m]).map(k => parseInt(k, 10)),
              numOfEmployees,
            );
          }
          price += priceTable[m][tier][term];
        });
      } else {
        let tier = tierSearch(
          Object.keys(priceTable[module]).map(k => parseInt(k, 10)),
          3,
        );
        if (numOfEmployees > 3) {
          tier = tierSearch(
            Object.keys(priceTable[module]).map(k => parseInt(k, 10)),
            numOfEmployees,
          );
        }
        price = priceTable[module][tier][term];
      }
    }

    return price;
  };

  const onNumOfEmployeeInputChange = e => {
    let value = e.target.value.trim();

    if (/^(\s*|\d+)$/.test(value)) {
      setInputVal(value);
      if (value !== '') {
        setIsLoading(true);
        const convertedVal = parseInt(value, 10);

        if (convertedVal >= 3 && convertedVal < 400) {
          setSliderVal([convertedVal]);
        } else if (convertedVal > 399) {
          setSliderVal([400]);
        }
      }
    }
  };

  useEffect(() => {
    const employeeCount = debouncedInputVal.trim();
    if (employeeCount !== '') {
      const price = calculatePrice(
        selectedMods,
        term,
        parseInt(employeeCount, 10),
      );
      setSumOfModulePrices(price);
      setIsLoading(false);
    }
  }, [selectedMods, term, debouncedInputVal]);

  return (
    <Container className="bg-ktblue-600 py-10">
      <div className="text-center mb-4">
        <h1 className="text-white text-4xl font-alata">
          <span className="text-ktorange-500">Scale better</span> with the{' '}
          <span className="text-ktorange-500">right</span> plan
        </h1>
      </div>
      <div className="bg-white shadow-md px-10 py-6 rounded-md">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="lg:col-span-2">
            <h3 className="text-xl font-semibold">Estimate Total Cost</h3>
            <p>Total employees in your company</p>
            <div className="my-4">
              <div>
                <Slider
                  orientation="horizontal"
                  value={sliderVal}
                  min={3}
                  max={400}
                  step={1}
                  aria-label="value"
                  onValueChange={onSliderValChange}
                />
              </div>
              <div className="flex justify-between">
                <p>3+</p>
                <p>400+</p>
              </div>
            </div>
            <div>
              <h3 className="text-xl font-semibold">Add-ons</h3>
              <p>Customize and enhance your plan with additional features</p>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                <CalculatorModule
                  name="E Payroll"
                  icon={payrollIcon}
                  checkedColor={{
                    checkbox: 'radix-state-checked:bg-payroll-500',
                    bg: 'bg-payroll-200',
                  }}
                  defaultChecked
                  onChange={checked => {
                    let newMods = [...selectedMods];
                    if (checked) {
                      newMods.push('payroll');
                    } else {
                      newMods = newMods.filter(mod => mod !== 'payroll');
                    }
                    return setSelectedMods(newMods);
                  }}
                />
                <CalculatorModule
                  name="E Leave"
                  icon={leaveIcon}
                  checkedColor={{
                    checkbox: 'radix-state-checked:bg-leave-500',
                    bg: 'bg-leave-200',
                  }}
                  onChange={checked => {
                    let newMods = [...selectedMods];
                    if (checked) {
                      newMods.push('leave');
                    } else {
                      newMods = newMods.filter(mod => mod !== 'leave');
                    }
                    return setSelectedMods(newMods);
                  }}
                />
                <CalculatorModule
                  name="E Claim"
                  icon={claimIcon}
                  checkedColor={{
                    checkbox: 'radix-state-checked:bg-claim-500',
                    bg: 'bg-claim-200',
                  }}
                  onChange={checked => {
                    let newMods = [...selectedMods];
                    if (checked) {
                      newMods.push('claim');
                    } else {
                      newMods = newMods.filter(mod => mod !== 'claim');
                    }
                    return setSelectedMods(newMods);
                  }}
                />
                <CalculatorModule
                  name="E Attendance"
                  icon={attendanceIcon}
                  checkedColor={{
                    checkbox: 'radix-state-checked:bg-attendance-500',
                    bg: 'bg-attendance-200',
                  }}
                  onChange={checked => {
                    let newMods = [...selectedMods];
                    if (checked) {
                      newMods.push('attendance');
                    } else {
                      newMods = newMods.filter(mod => mod !== 'attendance');
                    }
                    return setSelectedMods(newMods);
                  }}
                />
                <CalculatorModule
                  name="HR Premium"
                  icon={hrPremiumIcon}
                  checkedColor={{
                    checkbox: 'radix-state-checked:bg-hrpremium-500',
                    bg: 'bg-hrpremium-200',
                  }}
                  onChange={checked => {
                    let newMods = [...selectedMods];
                    if (checked) {
                      newMods.push('hrPremium');
                    } else {
                      newMods = newMods.filter(mod => mod !== 'hrPremium');
                    }
                    return setSelectedMods(newMods);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="bg-ktblue-100 px-4 py-8 text-center flex justify-center flex-col rounded-md">
            <div>
              {sliderVal[0] < 400 ? (
                <>
                  <div className="inline-block py-2">
                    <Switch
                      onCheckedChange={val =>
                        setTerm(val ? 'yearly' : 'monthly')
                      }
                    />
                    <p className="ml-2 text-sm inline-block">Yearly billing</p>
                  </div>
                  <h3 className="text-lg font-semibold my-2">
                    <span>
                      Up to
                      <input
                        className="w-16 h-10 mx-2 text-center"
                        value={inputVal}
                        onChange={onNumOfEmployeeInputChange}
                      />
                      employees
                    </span>
                  </h3>
                  <p className="text-sm text-gray-500">That's a total of:</p>
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <h2 className="mb-6">
                      <span className="text-2xl font-bold">
                        RM {sumOfModulePrices}
                      </span>{' '}
                      <span className="text-sm">/ {term}</span>
                    </h2>
                  )}
                  <Button
                    className="bg-ktblue-500 hover:bg-ktblue-600"
                    href="https://app.kakitangan.com/signup"
                    type="link"
                  >
                    Get Started Now
                  </Button>
                </>
              ) : (
                <>
                  <h3 className="text-3xl text-ktblue-600 font-alata font-bold mb-4">
                    Custom Pricing
                  </h3>
                  <p className="max-w-[70%] mx-auto mb-6">
                    If you have more than 400 employees, please contact sales
                    for a personalized quote.
                  </p>
                  <Button
                    className="bg-ktblue-500 hover:bg-ktblue-600"
                    href={`${domain}/contact-us"`}
                    type="link"
                  >
                    Speak to Sales
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

PriceCalculator.schema = {
  name: 'priceCalculator',
  label: 'Pricing: Calculator',
  getDefaultProps: () => ({}),
  sideEditProps: [],
};

export default PriceCalculator;
