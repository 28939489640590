import React from 'react';
import { types, Image, Text } from 'react-bricks/frontend';

interface TeamsStyleCItemProps {
  index: number;
  memberImg: types.IImageSource;
  memberName: string;
  memberPosition: string;
}

const TeamsStyleCItem: types.Brick<TeamsStyleCItemProps> = ({
  index,
  ...rest
}) => {
  return (
    <div
      className={`basis-1/5 py-12 ${
        index % 2 === 0 ? 'bg-ktblue-500' : 'bg-ktblue-600'
      }`}
      {...rest}
    >
      <Image
        propName="memberImg"
        alt="altText"
        maxWidth={100}
        aspectRatio={1}
        imageClassName="rounded-full"
        renderWrapper={({ children }) => (
          <div className="w-20 h-20 mx-auto mb-4 rounded-full drop-shadow-md">
            {children}
          </div>
        )}
      />
      <div>
        <div className="text-center text-white">
          <Text
            propName="memberName"
            placeholder="Name"
            renderBlock={({ children }) => (
              <p className="font-bold">{children}</p>
            )}
          />
          <Text
            propName="memberPosition"
            placeholder="Position"
            renderBlock={({ children }) => (
              <p className="text-sm">{children}</p>
            )}
          />
        </div>
      </div>
    </div>
  );
};

TeamsStyleCItem.schema = {
  name: 'teamsStyleCItem',
  label: 'Teams: Style C Item',
  hideFromAddMenu: true,
  getDefaultProps: () => ({
    memberName: 'John Doe',
    memberPosition: 'HR Dude',
  }),
  sideEditProps: [],
};

export default TeamsStyleCItem;
