import React, { useState } from 'react';
import cx from 'classnames';
import Checkbox from './Checkbox';

interface CalculatorModuleProps {
  icon: any;
  checkedColor: {
    checkbox: string;
    bg: string;
  };
  name: string;
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
}

const CalculatorModule: React.FC<CalculatorModuleProps> = ({
  icon,
  checkedColor,
  name,
  defaultChecked,
  onChange,
}) => {
  const [checked, setChecked] = useState(!!defaultChecked);

  return (
    <div
      className={cx(
        'p-2 border border-solid border-gray-300 rounded-md flex flex-row cursor-pointer',
        { 'bg-neutral-100': !checked },
        { [checkedColor.bg]: checked },
      )}
      onClick={() => {
        setChecked(!checked);
        if (onChange) {
          return onChange(!checked);
        }
      }}
    >
      <Checkbox
        className={cx('radix-state-unchecked:bg-white', checkedColor.checkbox)}
        checked={checked}
        onCheckedChange={(checked: boolean) => {
          setChecked(checked);
          if (onChange) {
            return onChange(checked);
          }
        }}
      >
        <div className="flex w-8 h-8 justify-center items-center rounded-full bg-white">
          <img src={icon} width="20" />
        </div>
        <p className="ml-2">{name}</p>
      </Checkbox>
    </div>
  );
};

export default CalculatorModule;
