import React from 'react';
import { types, RichText } from 'react-bricks/frontend';

interface ExplainersTwoByOneProps {
  sideImg: types.IImageSource;
  imgAlign: string;
  bgColor: types.IColor;
  titleColor: types.IColor;
  descColor: types.IColor;
}

const ExplainersTwoByOne: types.Brick<ExplainersTwoByOneProps> = ({
  sideImg,
  imgAlign,
  bgColor,
  titleColor,
  descColor,
}) => {
  return (
    <div className="grid grid-cols-2 w-full min-h-[280px]">
      <div
        className={`${imgAlign}`}
        style={{
          backgroundImage: `url(${sideImg?.src})`,
        }}
      />
      <div
        className={`text-white px-20 py-8 flex items-center ${
          bgColor?.className
        } ${imgAlign === 'order-1' ? 'order-2' : 'order-1'}`}
      >
        <div>
          <RichText
            propName="title"
            placeholder="Type a text..."
            renderBlock={({ children }) => (
              <h2
                className={`text-2xl font-bold mb-2 ${titleColor?.className}`}
              >
                {children}
              </h2>
            )}
          />
          <RichText
            propName="description"
            placeholder="Type a text..."
            renderBlock={({ children }) => (
              <p className={`text-base ${descColor?.className}`}>{children}</p>
            )}
          />
        </div>
      </div>
    </div>
  );
};

ExplainersTwoByOne.schema = {
  name: 'explainersTwoByOne',
  label: 'Explainers: 2x1 Grid',
  getDefaultProps: () => ({
    title: 'Lorem Ipsum Dolor',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat recusandae sit temporibus. Mollitia sequi dolorum odit? Architecto rem, beatae aperiam commodi iste laborum quis natus in deleniti sed corrupti esse.',
    imgAlign: 'order-1',
    bgColor: {
      color: '#3C819A',
      className: 'bg-ktblue-500',
    },
    titleColor: {
      color: '#FFFFFF',
      className: 'text-white',
    },
    descColor: {
      color: '#FFFFFF',
      className: 'text-white',
    },
  }),
  sideEditProps: [
    {
      name: 'sideImg',
      label: 'Image',
      type: types.SideEditPropType.Image,
      imageOptions: {
        maxWidth: 960,
      },
    },
    {
      name: 'imgAlign',
      label: 'Image Alignment',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Radio,
        options: [
          { value: 'order-1', label: 'Left' },
          { value: 'order-2', label: 'Right' },
        ],
      },
    },
    {
      name: 'bgColor',
      label: 'Background Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'bg-ktblue-500',
            },
          },
          {
            label: 'KT Blue (Light)',
            value: {
              color: '#65AAC3',
              className: 'bg-ktblue-300',
            },
          },
          {
            label: 'KT Blue (Lighter)',
            value: {
              color: '#F1F7F9',
              className: 'bg-ktblue-50',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'bg-ktorange-500',
            },
          },
          {
            label: 'KT Orange (Light)',
            value: {
              color: '#F8BC7E',
              className: 'bg-ktorange-300',
            },
          },
          {
            label: 'KT Orange (Lighter)',
            value: {
              color: '#FCE4CC',
              className: 'bg-ktorange-100',
            },
          },
        ],
      },
    },
    {
      name: 'titleColor',
      label: 'Title Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'Black',
            value: {
              color: '#171717',
              className: 'text-neutral-900',
            },
          },
          {
            label: 'White',
            value: {
              color: '#FFFFFF',
              className: 'text-white',
            },
          },
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'text-ktblue-500',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'text-ktorange-500',
            },
          },
        ],
      },
    },
    {
      name: 'descColor',
      label: 'Description Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'Black',
            value: {
              color: '#171717',
              className: 'text-neutral-900',
            },
          },
          {
            label: 'White',
            value: {
              color: '#FFFFFF',
              className: 'text-white',
            },
          },
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'text-ktblue-500',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'text-ktorange-500',
            },
          },
        ],
      },
    },
  ],
};

export default ExplainersTwoByOne;
