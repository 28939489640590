import React from 'react';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';

interface HubspotFormProps {
  formId: string;
}

const HubspotForm: React.FC<HubspotFormProps> = ({ formId }) => {
  useHubspotForm({
    portalId: '20136034',
    formId,
    target: `#hsForm_${formId}`,
  });

  return (
    <div className="hs-form">
      <div id={`hsForm_${formId}`} />
    </div>
  );
};

export default HubspotForm;
