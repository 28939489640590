import React from 'react';
import { types, Repeater } from 'react-bricks/frontend';
import Container from '../../../../components/Container';

const FeaturesStyleA: types.Brick = () => {
  return (
    <Container>
      <div className="flex flex-row flex-wrap justify-evenly items-center w-full py-8 gap-4 min-h-[35vh]">
        <Repeater propName="featuresStyleAItems" />
      </div>
    </Container>
  );
};

FeaturesStyleA.schema = {
  name: 'featuresStyleA',
  label: 'Features: Style A',
  getDefaultProps: () => ({
    featuresStyleAItems: [],
  }),
  sideEditProps: [],
  repeaterItems: [
    {
      name: 'featuresStyleAItems',
      itemType: 'featuresStyleAItem',
      itemLabel: 'Item',
      min: 2,
    },
  ],
};

export default FeaturesStyleA;
