import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import type { CheckboxProps } from '@radix-ui/react-checkbox';
import cx from 'classnames';
import React from 'react';

const Checkbox = ({ children, className, ...rest }: CheckboxProps) => {
  return (
    <div className="flex items-center">
      <CheckboxPrimitive.Root
        className={cx(
          'flex h-4 w-4 items-center justify-center rounded-sm radix-state-unchecked:border',
          'focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75',
          className,
        )}
        {...rest}
      >
        <CheckboxPrimitive.Indicator>
          <i
            className="self-center text-white gg-check"
            style={{ transform: 'scale(0.8)' }}
          />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
      <div className="ml-3 text-sm text-gray-900 flex items-center">
        {children}
      </div>
    </div>
  );
};

export default Checkbox;
