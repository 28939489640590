import React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import cx from 'classnames';

const Slider = ({ ...rest }) => {
  return (
    <SliderPrimitive.Root
      className="relative flex h-5 w-full touch-none items-center"
      {...rest}
    >
      <SliderPrimitive.Track className="relative h-1 w-full grow rounded-full bg-gray-200">
        <SliderPrimitive.Range className="absolute h-full rounded-full bg-ktblue-500" />
      </SliderPrimitive.Track>
      <SliderPrimitive.Thumb
        className={cx(
          'block h-5 w-5 rounded-full bg-white border-ktblue-500 border-2',
          'focus:outline-none focus-visible:ring focus-visible:ring-ktblue-500 focus-visible:ring-opacity-75',
        )}
      />
    </SliderPrimitive.Root>
  );
};

export default Slider;
