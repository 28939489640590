import React from 'react';
import { types, Text, Repeater } from 'react-bricks/frontend';
import cx from 'classnames';

import Container from '../../../../components/Container';
import Button from '../../../../components/Button';

interface FeaturesStyleCProps {
  enableTitle: boolean;
  enableDescription: boolean;
  enableCards: boolean;
  alignment: string;
  bgColor: types.IColor;
}

const domain = 'https://www.kakitangan.com';

const FeaturesStyleC: types.Brick<FeaturesStyleCProps> = ({
  enableTitle,
  enableDescription,
  enableCards,
  alignment,
  bgColor,
}) => {
  return (
    <Container className={cx('py-8 min-h-[35vh]', bgColor?.className)}>
      <div className={cx({ 'mb-4': enableTitle || enableDescription })}>
        {enableTitle ? (
          <Text
            propName="sectionTitle"
            placeholder="Title"
            renderBlock={({ children }) => (
              <h2 className={`text-3xl font-bold font-alata`}>{children}</h2>
            )}
          />
        ) : null}
        {enableDescription ? (
          <Text
            propName="sectionDescription"
            placeholder="Description"
            renderBlock={({ children }) => (
              <p className={`text-base mt-2`}>{children}</p>
            )}
          />
        ) : null}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <Repeater
          propName="featuresStyleCItems"
          itemProps={{ alignment, enableCards }}
        />
        <div className="bg-ktblue-100 w-full min-h-[240px] rounded-md py-10 px-8">
          <Button
            type="link"
            href="https://app.kakitangan.com/signup"
            className="bg-ktorange-500 hover:bg-ktorange-600 w-full mb-2"
          >
            Get Started Now
          </Button>
          <Button
            type="link"
            href={`${domain}/contact-us`}
            className="bg-white hover:bg-neutral-200 w-full border border-1 border-neutral-900 mb-4"
          >
            Request Demo
          </Button>
          <div>
            <p>
              Contact sales to request enterprise pricing for larger
              organization.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

FeaturesStyleC.schema = {
  name: 'featuresStyleC',
  label: 'Features: Style C',
  getDefaultProps: () => ({
    featuresStyleCItems: [],
    enableTitle: true,
    sectionTitle: 'Check out our features',
    enableDescription: true,
    sectionDescription:
      'Dignissimos dolores quam, nobis quaerat laboriosam. Lorem ipsum dolor sit amet',
    enableCards: true,
    alignment: 'left',
    bgColor: {
      color: '#FFFFFF',
      className: 'bg-white',
    },
  }),
  sideEditProps: [
    {
      name: 'enableTitle',
      label: 'Enable Title?',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'enableDescription',
      label: 'Enable Description?',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'enableCards',
      label: 'Enable Card Design?',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'alignment',
      label: 'Feature Content Alignment',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Radio,
        options: [
          { value: 'left', label: 'Left' },
          { value: 'center', label: 'Center' },
          { value: 'right', label: 'Right' },
        ],
      },
    },
    {
      name: 'bgColor',
      label: 'Background Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'White',
            value: {
              color: '#FFFFFF',
              className: 'bg-white',
            },
          },
          {
            label: 'Light Gray',
            value: {
              color: '#F5F5F5',
              className: 'bg-neutral-100',
            },
          },
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'bg-ktblue-500',
            },
          },
          {
            label: 'KT Blue (Light)',
            value: {
              color: '#65AAC3',
              className: 'bg-ktblue-300',
            },
          },
          {
            label: 'KT Blue (Lighter)',
            value: {
              color: '#F1F7F9',
              className: 'bg-ktblue-50',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'bg-ktorange-500',
            },
          },
          {
            label: 'KT Orange (Light)',
            value: {
              color: '#F8BC7E',
              className: 'bg-ktorange-300',
            },
          },
          {
            label: 'KT Orange (Lighter)',
            value: {
              color: '#FCE4CC',
              className: 'bg-ktorange-100',
            },
          },
        ],
      },
    },
  ],
  repeaterItems: [
    {
      name: 'featuresStyleCItems',
      itemType: 'featuresStyleCItem',
      itemLabel: 'Item',
      min: 2,
    },
  ],
};

export default FeaturesStyleC;
