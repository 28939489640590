import React from 'react';
import { types, Text, Repeater } from 'react-bricks/frontend';

interface TeamsStyleAProps {
  sectionTitle: string;
  sectionDescription: string;
  fullWidth: boolean;
}

const TeamsStyleA: types.Brick<TeamsStyleAProps> = ({ fullWidth }) => {
  return (
    <div className="my-8">
      <div className="text-center mb-6">
        <div className="w-1/4 mx-auto">
          <Text
            propName="sectionTitle"
            placeholder="Title"
            renderBlock={({ children }) => (
              <h2 className="text-4xl font-bold">{children}</h2>
            )}
          />
          <div className="w-12 my-10 mx-auto">
            <hr className="border-t-2" />
          </div>
          <Text
            propName="sectionDescription"
            placeholder="Description"
            renderBlock={({ children }) => (
              <p className="text-base">{children}</p>
            )}
          />
        </div>
      </div>
      <div
        className={`grid grid-cols-4 justify-evenly items-center w-full gap-4 min-h-[35vh] ${
          !fullWidth ? 'mx-auto px-72' : ''
        }`}
      >
        <Repeater propName="teamsStyleAItems" />
      </div>
    </div>
  );
};

TeamsStyleA.schema = {
  name: 'teamsStyleA',
  label: 'Teams: Style A',
  getDefaultProps: () => ({
    sectionTitle: 'Our Team Members',
    sectionDescription:
      'Dignissimos dolores quam, nobis quaerat laboriosam sed quia dolor molestias maiores aperiam nam necessitatibus.',
    teamsStyleAItems: [],
    fullWidth: false,
  }),
  sideEditProps: [
    {
      name: 'fullWidth',
      label: 'Edge-to-edge?',
      type: types.SideEditPropType.Boolean,
    },
  ],
  repeaterItems: [
    {
      name: 'teamsStyleAItems',
      itemType: 'teamsStyleAItem',
      itemLabel: 'Item',
      min: 4,
    },
  ],
};

export default TeamsStyleA;
