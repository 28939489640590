import React from 'react';
import cx from 'classnames';

interface ContainerProps {
  children: React.ReactElement | React.ReactElement[];
  className?: string;
  style?: React.CSSProperties;
}

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  (props, ref) => (
    <div
      className={cx(
        'px-8 md:px-20 lg:px-30 xl:px-30 2xl:px-52 3xl:px-96',
        props.className,
      )}
      style={{ ...props.style }}
      {...(ref && { ref })}
    >
      {props.children}
    </div>
  ),
);

export default Container;
