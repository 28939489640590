import React from 'react';
import { types, RichText, Image } from 'react-bricks';
import HubspotForm from '../../../components/HubspotForm';

interface FormSectionWithTextProps {
  formId: string;
  contentPlacement: string;
  imgPlacement: string;
  formBgColor: types.IColor;
  enableImg: boolean;
}

const FormSectionWithText: types.Brick<FormSectionWithTextProps> = ({
  formId,
  contentPlacement,
  imgPlacement,
  formBgColor,
  enableImg,
}) => {
  return (
    <div className="my-14 px-52">
      <div className="grid grid-cols-2 gap-20 items-center">
        <div
          className={`${contentPlacement === 'left' ? 'order-1' : 'order-2'}`}
        >
          <div className="flex flex-col">
            {enableImg ? (
              <div
                className={`block mx-auto w-full ${
                  imgPlacement === 'top' ? 'order-1 mb-6' : 'order-2 mt-6'
                }`}
              >
                <Image propName="image" alt="altText" maxWidth={200} />
              </div>
            ) : null}
            <div className={imgPlacement === 'top' ? 'order-2' : 'order-1'}>
              <RichText
                propName="title"
                placeholder="Title"
                renderBlock={({ children }) => (
                  <h3 className="text-4xl mb-2">{children}</h3>
                )}
                allowedFeatures={[
                  types.RichTextFeatures.Bold,
                  types.RichTextFeatures.Italic,
                ]}
              />
              <RichText
                propName="description"
                placeholder="Description"
                renderBlock={({ children }) => (
                  <p className="text-base">{children}</p>
                )}
                allowedFeatures={[
                  types.RichTextFeatures.Bold,
                  types.RichTextFeatures.Italic,
                ]}
              />
            </div>
          </div>
        </div>
        <div
          className={`${formBgColor?.className} rounded-sm px-4 py-12 w-full ${
            contentPlacement === 'left' ? 'order-2' : 'order-1'
          }`}
        >
          {formId !== '' ? <HubspotForm key={formId} formId={formId} /> : null}
        </div>
      </div>
    </div>
  );
};

FormSectionWithText.schema = {
  name: 'FormSectionWithText',
  label: 'Forms: With Text',
  getDefaultProps: () => ({
    formId: '',
    title: 'Lorem Ipsum',
    description:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Labore, temporibus quas nisi voluptatum facilis expedita autem praesentium quisquam, necessitatibus aperiam, quis laudantium sapiente. Dicta unde quo aspernatur quidem blanditiis adipisci?',
    contentPlacement: 'left',
    imgPlacement: 'top',
    formBgColor: {
      color: '#3C819A',
      className: 'bg-ktblue-500',
    },
    enableImg: true,
  }),
  sideEditProps: [
    {
      name: 'formId',
      label: 'Hubspot Form ID',
      type: types.SideEditPropType.Text,
    },
    {
      name: 'contentPlacement',
      label: 'Content Placement',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Radio,
        options: [
          { label: 'Left', value: 'left' },
          { label: 'Right', value: 'right' },
        ],
      },
    },
    {
      name: 'enableImg',
      label: 'Use an image?',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'imgPlacement',
      label: 'Image placement',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Radio,
        options: [
          { label: 'Top', value: 'top' },
          { label: 'Bottom', value: 'bottom' },
        ],
      },
      show: props => !!props.enableImg,
    },
    {
      name: 'formBgColor',
      label: 'Form Background Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'bg-ktblue-500',
            },
          },
          {
            label: 'KT Blue (Light)',
            value: {
              color: '#65AAC3',
              className: 'bg-ktblue-300',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'bg-ktorange-500',
            },
          },
          {
            label: 'KT Orange (Light)',
            value: {
              color: '#F8BC7E',
              className: 'bg-ktorange-300',
            },
          },
        ],
      },
    },
  ],
};

export default FormSectionWithText;
