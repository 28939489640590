import React from 'react';
import { types } from 'react-bricks/frontend';

interface TeamsStyleAItemProps {
  memberImg: types.IImageSource;
  memberName: string;
  memberPosition: string;
}

const TeamsStyleAItem: types.Brick<TeamsStyleAItemProps> = ({
  memberImg,
  memberName,
  memberPosition,
  ...rest
}) => {
  return (
    <div className="basis-1/4 w-full h-auto relative group" {...rest}>
      <div
        className="block w-full h-56 bg-gray-100 bg-center bg-cover bg-no-repeat"
        style={{ backgroundImage: `url(${memberImg?.src})` }}
      />
      <div className="flex justify-center items-center absolute inset-0 h-full w-full opacity-0 group-hover:opacity-80 bg-ktblue-500">
        <div className="text-white text-center">
          <p className="font-bold">{memberName}</p>
          <p className="text-sm">{memberPosition}</p>
        </div>
      </div>
    </div>
  );
};

TeamsStyleAItem.schema = {
  name: 'teamsStyleAItem',
  label: 'Teams: Style A Item',
  hideFromAddMenu: true,
  getDefaultProps: () => ({
    memberName: 'John Doe',
    memberPosition: 'HR Dude',
  }),
  sideEditProps: [
    {
      name: 'memberImg',
      label: 'Image',
      type: types.SideEditPropType.Image,
      imageOptions: {
        maxWidth: 300,
      },
    },
    {
      name: 'memberName',
      label: 'Name',
      type: types.SideEditPropType.Text,
    },
    {
      name: 'memberPosition',
      label: 'Position in Kakitangan',
      type: types.SideEditPropType.Text,
    },
  ],
};

export default TeamsStyleAItem;
