import React from 'react';
import { types, Repeater } from 'react-bricks/frontend';
import Container from '../../../../components/Container';

const FeaturesStyleB: types.Brick = () => {
  return (
    <Container>
      <div className="flex flex-row flex-wrap justify-evenly items-center py-20 gap-4">
        <Repeater propName="featuresStyleBItems" />
      </div>
    </Container>
  );
};

FeaturesStyleB.schema = {
  name: 'featuresStyleB',
  label: 'Features: Style B',
  getDefaultProps: () => ({
    featuresStyleBItems: [],
  }),
  sideEditProps: [],
  repeaterItems: [
    {
      name: 'featuresStyleBItems',
      itemType: 'featuresStyleBItem',
      itemLabel: 'Item',
      min: 2,
    },
  ],
};

export default FeaturesStyleB;
