import React from 'react';
import { types, Text, Image } from 'react-bricks/frontend';

interface FeaturesStyleAItemProps {
  enableIconBg: boolean;
  iconBgColor: types.IColor;
  iconOrImg: string;
  iconName: string;
  iconColor: string;
}

const FeaturesStyleAItem: types.Brick<FeaturesStyleAItemProps> = ({
  enableIconBg,
  iconBgColor,
  iconOrImg,
  iconName,
  iconColor,
  ...rest
}) => {
  return (
    <div className="basis-1/4" {...rest}>
      <div className="flex gap-4">
        <div className="mr-3">
          <div
            className={`w-14 h-14 flex justify-center items-center ${
              enableIconBg && `rounded-md ${iconBgColor?.className}`
            }`}
          >
            {iconOrImg === 'icon' ? (
              <i
                className={`${iconName}`}
                style={{ transform: 'scale(1.25)', color: iconColor }}
              />
            ) : (
              <Image
                propName="iconImg"
                alt="altText"
                maxWidth={64}
                aspectRatio={1}
                renderWrapper={({ children }) => (
                  <div className="w-9 h-9">{children}</div>
                )}
              />
            )}
          </div>
        </div>
        <div>
          <Text
            propName="title"
            renderBlock={({ children }) => (
              <h3 className="mb-2 text-2xl font-bold">{children}</h3>
            )}
            placeholder="Title of feature"
          />
          <Text
            propName="description"
            renderBlock={({ children }) => (
              <p className="text-sm">{children}</p>
            )}
            placeholder="Description of feature goes here."
          />
        </div>
      </div>
    </div>
  );
};

FeaturesStyleAItem.schema = {
  name: 'featuresStyleAItem',
  label: 'Features: Style A Item',
  hideFromAddMenu: true,
  getDefaultProps: () => ({
    title: 'Great Features',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis quibusdam eius libero atque eligendi esse eveniet in suscipit qui. Minima placeat id nam cum unde minus modi dignissimos quae aperiam.',
    enableIconBg: true,
    iconBgColor: {
      color: '#3C819A',
      className: 'bg-ktblue-500',
    },
    iconOrImg: 'icon',
    iconName: 'gg-smartphone-chip',
    iconColor: '#FFFFFF',
  }),
  sideEditProps: [
    {
      name: 'enableIconBg',
      label: 'Enable icon background',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'iconBgColor',
      label: 'Icon Background Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'bg-ktblue-500',
            },
          },
          {
            label: 'KT Blue (Light)',
            value: {
              color: '#65AAC3',
              className: 'bg-ktblue-300',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'bg-ktorange-500',
            },
          },
          {
            label: 'KT Orange (Light)',
            value: {
              color: '#F8BC7E',
              className: 'bg-ktorange-300',
            },
          },
        ],
      },
      show: props => !!props.enableIconBg,
    },
    {
      name: 'iconOrImg',
      label: 'Use CSS Icon or Image?',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Radio,
        options: [
          { value: 'icon', label: 'CSS icon' },
          { value: 'img', label: 'Image' },
        ],
      },
    },
    {
      name: 'iconName',
      label: 'Icon',
      type: types.SideEditPropType.Text,
      show: props => props.iconOrImg === 'icon',
    },
    {
      name: 'iconColor',
      label: 'Icon Color Hex Code',
      type: types.SideEditPropType.Text,
      show: props => props.iconOrImg === 'icon',
    },
  ],
};

export default FeaturesStyleAItem;
