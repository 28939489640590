import React from 'react';
import { types, Text, Repeater } from 'react-bricks/frontend';

interface TeamsStyleBProps {
  sectionTitle: string;
  sectionDescription: string;
  fullWidth: boolean;
}

const TeamsStyleB: types.Brick<TeamsStyleBProps> = ({ fullWidth }) => {
  return (
    <div className="my-8">
      <div className="text-center mb-10">
        <div className="w-1/2 mx-auto">
          <Text
            propName="sectionTitle"
            placeholder="Title"
            renderBlock={({ children }) => (
              <h2 className="text-4xl mb-2 font-bold">{children}</h2>
            )}
          />
          <Text
            propName="sectionDescription"
            placeholder="Description"
            renderBlock={({ children }) => (
              <p className="text-base">{children}</p>
            )}
          />
        </div>
      </div>
      <div className="flex flex-wrap justify-evenly items-center w-full mx-auto px-24">
        <Repeater propName="teamsStyleBItems" />
      </div>
    </div>
  );
};

TeamsStyleB.schema = {
  name: 'teamsStyleB',
  label: 'Teams: Style B',
  getDefaultProps: () => ({
    sectionTitle: 'Our Team Members',
    sectionDescription:
      'Dignissimos dolores quam, nobis quaerat laboriosam. Lorem ipsum dolor sit amet',
    teamsStyleBItems: [],
  }),
  sideEditProps: [],
  repeaterItems: [
    {
      name: 'teamsStyleBItems',
      itemType: 'teamsStyleBItem',
      itemLabel: 'Item',
      min: 2,
    },
  ],
};

export default TeamsStyleB;
