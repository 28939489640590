import React from 'react';
import { types, Text, Image } from 'react-bricks/frontend';

interface TestimonialsStaticThreeItemProps {
  quoteColor: types.IColor;
  nameColor: types.IColor;
  companyColor: types.IColor;
}

const TestimonialsStaticThreeItem: types.Brick<
  TestimonialsStaticThreeItemProps
> = ({ quoteColor, nameColor, companyColor, ...rest }) => {
  return (
    <div className="basis-1/4 py-4" {...rest}>
      <div className="flex flex-col gap-4">
        <div>
          <Image
            propName="image"
            alt="altText"
            maxWidth={300}
            aspectRatio={1}
          />
        </div>
        <div>
          <Text
            propName="quote"
            renderBlock={({ children }) => (
              <p className={`mb-2 italic ${quoteColor?.className}`}>
                {children}
              </p>
            )}
            placeholder="A quotation goes here."
          />
          <Text
            propName="name"
            renderBlock={({ children }) => (
              <p className={`font-bold ${nameColor?.className}`}>{children}</p>
            )}
            placeholder="Name of the person"
          />
          <Text
            propName="company"
            renderBlock={({ children }) => (
              <p className={companyColor?.className}>{children}</p>
            )}
            placeholder="Company"
          />
        </div>
      </div>
    </div>
  );
};

TestimonialsStaticThreeItem.schema = {
  name: 'testimonialsStaticThreeItem',
  label: 'Testimonials: Static Three Item',
  hideFromAddMenu: true,
  getDefaultProps: () => ({
    quote:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis quibusdam eius libero atque eligendi esse eveniet in suscipit qui. Minima placeat id nam cum unde minus modi dignissimos quae aperiam.',
    name: 'John Doe',
    company: 'ABC Trading Sdn Bhd',
    quoteColor: {
      color: '#1A202C',
      className: 'text-gray-800',
    },
    nameColor: {
      color: '#1A202C',
      className: 'text-gray-800',
    },
    companyColor: {
      color: '#1A202C',
      className: 'text-gray-800',
    },
  }),
  sideEditProps: [
    {
      name: 'quoteColor',
      label: 'Quote Text Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'Black',
            value: {
              color: '#1A202C',
              className: 'text-gray-800',
            },
          },
          {
            label: 'White',
            value: {
              color: '#FFFFFF',
              className: 'text-white',
            },
          },
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'text-ktblue-500',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'text-ktorange-500',
            },
          },
        ],
      },
    },
    {
      name: 'nameColor',
      label: 'Name Text Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'Black',
            value: {
              color: '#1A202C',
              className: 'text-gray-800',
            },
          },
          {
            label: 'White',
            value: {
              color: '#FFFFFF',
              className: 'text-white',
            },
          },
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'text-ktblue-500',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'text-ktorange-500',
            },
          },
        ],
      },
    },
    {
      name: 'companyColor',
      label: 'Company Text Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'Black',
            value: {
              color: '#1A202C',
              className: 'text-gray-800',
            },
          },
          {
            label: 'White',
            value: {
              color: '#FFFFFF',
              className: 'text-white',
            },
          },
          {
            label: 'KT Blue',
            value: {
              color: '#3C819A',
              className: 'text-ktblue-500',
            },
          },
          {
            label: 'KT Orange',
            value: {
              color: '#F49431',
              className: 'text-ktorange-500',
            },
          },
        ],
      },
    },
  ],
};

export default TestimonialsStaticThreeItem;
