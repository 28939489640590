import React from 'react';
import { types, Text, Repeater } from 'react-bricks/frontend';

interface TeamsStyleCProps {
  sectionTitle: string;
  sectionDescription: string;
}

const TeamsStyleC: types.Brick<TeamsStyleCProps> = () => {
  return (
    <div className="my-8">
      <div className="text-center mb-10">
        <div className="w-1/4 mx-auto">
          <Text
            propName="sectionTitle"
            placeholder="Title"
            renderBlock={({ children }) => (
              <h2 className="text-4xl font-bold">{children}</h2>
            )}
          />
          <div className="w-12 my-10 mx-auto">
            <hr className="border-t-2" />
          </div>
          <Text
            propName="sectionDescription"
            placeholder="Description"
            renderBlock={({ children }) => (
              <p className="text-base">{children}</p>
            )}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 justify-between items-center w-full px-72">
        <Repeater propName="teamsStyleCItems" />
      </div>
    </div>
  );
};

TeamsStyleC.schema = {
  name: 'teamsStyleC',
  label: 'Teams: Style C',
  getDefaultProps: () => ({
    sectionTitle: 'Our Team Members',
    sectionDescription:
      'Dignissimos dolores quam, nobis quaerat laboriosam. Lorem ipsum dolor sit amet',
    teamsStyleCItems: [],
  }),
  sideEditProps: [],
  repeaterItems: [
    {
      name: 'teamsStyleCItems',
      itemType: 'teamsStyleCItem',
      itemLabel: 'Item',
      min: 2,
    },
  ],
};

export default TeamsStyleC;
