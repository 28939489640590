import React from 'react';
import { types, RichText } from 'react-bricks/frontend';
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

interface FaqsStyleAItemProps {
  index: number;
  totalElements: number;
  itemsExpanded: number[];
  title: string;
  description: string;
}

const FaqsStyleAItem: types.Brick<FaqsStyleAItemProps> = ({
  index,
  totalElements,
  itemsExpanded,
  title,
  ...rest
}) => {
  return (
    <AccordionItem uuid={index} {...rest} className="mb-4">
      <AccordionItemHeading
        className={`text-lg rounded-md shadow-[0_5px_10px_5px_rgba(0,0,0,0.1)] ${
          itemsExpanded?.includes(index) ? 'bg-ktblue-500' : 'bg-white'
        }`}
      >
        <AccordionItemButton className="p-7 flex gap-2 justify-between">
          {title}
          <div>
            <i
              className={`${
                !itemsExpanded?.includes(index)
                  ? 'gg-chevron-down'
                  : 'gg-arrow-up'
              }`}
              style={{ transform: 'scale(0.85)' }}
            />
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="px-4 py-4 bg-white mt-5">
        <RichText
          propName="description"
          placeholder="Description"
          renderBlock={({ children }) => (
            <p className="text-gray-500">{children}</p>
          )}
          renderHighlight={({ children }) => (
            <span className="bg-ktorange-500 text-white">{children}</span>
          )}
          renderOL={({ children }) => (
            <ol className="list-decimal list-inside ml-8">{children}</ol>
          )}
          renderUL={({ children }) => (
            <ul className="list-disc list-inside ml-8">{children}</ul>
          )}
          renderLI={({ children }) => (
            <li className="text-gray-500 ">{children}</li>
          )}
          allowedFeatures={[
            types.RichTextFeatures.Bold,
            types.RichTextFeatures.Italic,
            types.RichTextFeatures.Highlight,
            types.RichTextFeatures.UnorderedList,
            types.RichTextFeatures.OrderedList,
          ]}
        />
      </AccordionItemPanel>
    </AccordionItem>
  );
};

FaqsStyleAItem.schema = {
  name: 'faqsStyleAItem',
  label: 'FAQs: Style A Item',
  hideFromAddMenu: true,
  getDefaultProps: () => ({
    title: 'What is a question, really?',
    description: 'Stop being so darn philosophical all the time man...',
  }),
  sideEditProps: [
    {
      name: 'title',
      label: 'Question/Title',
      type: types.SideEditPropType.Textarea,
    },
  ],
};

export default FaqsStyleAItem;
